<template>
    <div>
        <!-- Breadcrumbs -->
        <v-container v-if="smAndUp">
            <v-row>
                <v-col>
                    <v-breadcrumbs
                        :items="breadCrumbs"
                        divider=">"
                    ></v-breadcrumbs>
                </v-col>
            </v-row>
        </v-container>

        <!-- FAQ -->
        <v-container>
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <h1><heading>よくあるご質問</heading></h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" v-for="qa in qaList" :key="qa.q">
                    <qa :question="qa.q" :answer="qa.a"></qa>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </div>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import Qa from '@/components/faq/Qa.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
    },
    components: {
        Heading,
        FooterContents,
        Qa,
    },

    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: 'よくあるご質問',
                    disabled: true,
                    href: 'faq',
                },
            ]
        },
        qaList() {
            return [
                {
                    q:
                        "<span><span class='q-color'>Q1</span>" +
                        '：ジムは初めてですが大丈夫ですか？</span>',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：運動初心者の方を大歓迎しています！</span>',
                },
                {
                    q:
                        "<span><span class='q-color'>Q2</span>" +
                        '：なぜこんなに安いんですか？</span>',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：是非、当サイトのレボイストとは？の ページをご覧ください！</span>',
                },
                {
                    q:
                        "<span><span class='q-color'>Q3</span>" +
                        '：週1回でも効果はありますか？</span>',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：生活習慣が変わるので効果はあります！</span>',
                },
                {
                    q:
                        "<span><span class='q-color'>Q4</span>" +
                        '：糖質制限はありますか？</span>',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：一般の方にとって無理な食事制限は一切しません！</span>',
                },
                {
                    q:
                        "<span><span class='q-color'>Q5</span>" +
                        '：週2回受けることもできますか？</span>',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：ご希望の方はもちろん週2回受けることも可能です！</span>',
                },
                {
                    q:
                        "<span><span class='q-color'>Q6</span>" +
                        '：退会までの縛り期間はありますか？</span>',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：特にございません！お気軽にご入会ください！</span>',
                },
                {
                    q:
                        "<span><span class='q-color'>Q7</span>" +
                        '：同時間帯に他の方のレッスンはありますか？</span>',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：ございません！完全個別なので快適にレッスンをお受けいただけます！</span>',
                },
                {
                    q:
                        "<span><span class='q-color'>Q8</span>" +
                        '：更衣室はありますか？</span>',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：ございます！運動着でのご来館ももちろん可能です！</span>',
                },
                {
                    q:
                        "<span><span class='q-color'>Q9</span>" +
                        '：ウェアや室内シューズの貸出はありますか？</span>',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：レンタルシューズは23cm～28cmまで無料で貸出しています！</span>',
                },
                {
                    q:
                        "<span class='q-color'>Q10</span>" +
                        '：月会費のお支払い方法は？',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：口座振替となっております！</span>',
                },
                {
                    q:
                        "<span class='q-color'>Q11</span>" +
                        '：予約の入れ方は？',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：基本的に同じ曜日と時間を毎週固定して受ける方が多いです！</span>',
                },
                {
                    q:
                        "<span class='q-color'>Q12</span>" +
                        '：振替はできますか？',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：予約2日前のキャンセルであれば当月内で何回でも振替が可能です！</span>',
                },
                {
                    q:
                        "<span class='q-color'>Q13</span>" +
                        '：会員の男女比は？',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：男性：女性＝４：６ です！</span>',
                },
            ]
        },
    },
}
</script>

<style lang="scss">
.q-color {
    color: #1a0dab;
}
.a-color {
    color: #c30013;
}
</style>
