<template>
    <div class="mt-4">
        <trial-lesson></trial-lesson>
        <contact :shouldRevealButton="false"></contact>
        <logo></logo>
    </div>
</template>

<script>
import TrialLesson from '@/components/TrialLesson.vue'
import Contact from '@/components/Contact.vue'
import Logo from '@/components/Logo.vue'

export default {
    components: {
        TrialLesson,
        Contact,
        Logo,
    },
}
</script>

<style lang="scss" scoped></style>
