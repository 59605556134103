<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="6" md="2">
                <v-img
                    class="ma-6"
                    src="/static/pc_top/logo_revoist.svg"
                ></v-img>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col align="center">
                <div class="text-h5 my-4 font-weight-light">
                    パーソナルトレーニングを<br />受けやすく、続けやすく
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
