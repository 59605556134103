<template>
    <v-card class="blue-grey lighten-5" elevation="4">
        <v-card-title
            class="justify-center ma-auto"
            :class="smAndUp ? 'question' : null"
            v-html="question"
        >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title
            class="justify-center ma-auto"
            :class="smAndUp ? 'answer' : null"
            v-html="answer"
        >
        </v-card-title>
    </v-card>
</template>

<script>
export default {
    props: {
        question: {
            type: String,
            required: true,
        },
        answer: {
            type: String,
            required: true,
        },
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped>
.question {
    min-height: 100px;
}
.answer {
    min-height: 150px;
}
</style>
