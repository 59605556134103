<template>
    <v-container>
        <v-card
            outlined
            class="ma-auto trial-lesson"
            :max-width="smAndUp ? '80%' : '100%'"
        >
            <v-card-title
                class="text-h4 font-weight-light title text-center"
                :class="smAndUp ? 'text-h4' : 'text-h5'"
            >
                <span class="under">体験レッスンのご案内</span>
            </v-card-title>
            <v-img
                :key="imageTrialLesson"
                :src="imageTrialLesson"
                class="my-4"
            ></v-img>
            <v-card-text class="text-body-1 ma-auto">
                LINEで簡単に申込可能！男性も女性も、運動初心者も大歓迎！
                カラダの悩みなどカウンセリングをして、あなたに合ったトレーニングをご提案します。
                必要に応じて食事のアドバイスも可能です。
                まずは、お気軽に体験レッスンにお申込みください！ご質問もどうぞ！
            </v-card-text>
            <v-btn x-large text tile class="link-tag mt-6 py-auto">
                <router-link
                    class="text-decoration-none"
                    :to="{ name: 'Contact' }"
                >
                    <span class="text-h5 font-weight-light black--text"
                        >体験レッスンはこちら
                    </span>
                </router-link>
            </v-btn>
        </v-card>
    </v-container>
</template>

<script>
export default {
    computed: {
        imageTrialLesson() {
            return this.$vuetify.breakpoint.smAndUp
                ? '/static/pc_top/pixta_35383873_XL.png'
                : '/static/sp_top/img_tailen.png'
        },
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    display: block !important;
}
.trial-lesson {
    border-width: 1em;
    border-color: #f3e3b6;
}
.menu-2 {
    min-height: 300px;
}
.under {
    background: linear-gradient(transparent 70%, #f6cfcf 70%);
}

.link-tag {
    width: 100%;
    height: 100px;
    text-align: center;
    vertical-align: middle;
    border: 1px dashed;
    border-width: 1px 0 0 0;
    border-color: #666666;
}
.link-tag:after {
    font-family: 'Font Awesome 5 Free';
    content: '\f105';
    font-weight: 900;
    top: 50%;
    right: 10px;
    margin-top: -12px;
    position: absolute;
    color: #c30013;
}
</style>
