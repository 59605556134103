<template>
    <v-container>
        <v-row justify="center" :class="smAndUp ? 'ma-6' : null">
            <v-col cols="12" md="6" align="center" v-if="shouldRevealButton">
                <router-link
                    class="text-decoration-none"
                    :to="{ name: 'Contact' }"
                >
                    <v-btn x-large block class="white--text" color="#E25959">
                        体験レッスンはこちら >
                    </v-btn>
                </router-link>
            </v-col>
            <v-col cols="12" md="12" align="center">
                <v-card flat :width="smAndUp ? '33vw' : '100vw'">
                    <v-card-title class="text-h6 title text-center">
                        レボイスト オフィシャルSNS
                    </v-card-title>
                    <v-card-text>
                        <v-row justify="space-around">
                            <a
                                class="text-decoration-none"
                                target="_blank"
                                href="https://lin.ee/bFpWQ0A"
                            >
                                <v-icon x-large>fab fa-line</v-icon>
                            </a>
                            <a
                                class="text-decoration-none"
                                target="_blank"
                                href="https://www.facebook.com/gyotoku.revoist/"
                            >
                                <v-icon x-large>fab fa-facebook-square</v-icon>
                            </a>
                            <a
                                target="_blank"
                                class="text-decoration-none"
                                href="https://instagram.com/revoist.co.jp?igshid=ttqj4w4vush1"
                            >
                                <v-icon x-large>fab fa-instagram</v-icon>
                            </a>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <a href="https://yobikou.revoist.co.jp/" target="_blank">
                    <v-img :key="imageYobiko" :src="imageYobiko"></v-img>
                </a>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: {
        shouldRevealButton: {
            required: false,
            type: Boolean,
            default: true,
        },
    },
    computed: {
        imagePersonalTraining() {
            return '/static/pc_top/top_prsonal.png'
        },
        imageYobiko() {
            return '/static/pc_top/blog.png'
        },
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    display: block !important;
}
</style>
